import React from "react";
import { round, sample } from 'lodash-es'

const starterPrice = 0.01
const onePrice = 0.0006
const standardPrice = 0.028
const proPrice = 0.05
const ultimatePrice = 0.08
const isVariation = sample([true, false])

//const nextPricing = Date.now() > 1680299999000

const pricingData = {
  promoStandard: 199,
  promoPro: 299,
  ultimate: 499,
  monthlyStandard: 29,
  monthlyPro: 39,
  monthlyUltimate: 59,
  yearlyStandard: 174,
  yearlyPro: 234,
  yearlyUltimate: 354,
  "oneTopromoStandard": 179,
  "oneTopromoPro": 279,
  "oneToUltimate": 479,
  "promoStarterTopromoStandard": 124,
  "promoStarterTopromoPro": 224,
  "promoStarterToUltimate": 424,
  "promoStandardTopromoPro": 100,
  "promoStandardToUltimate": 300,
  "promoProToUltimate": 200,
  "promoUltimateToUltimate": 199
};

const allPlans = {
  structured: {
    ldJson2: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": "Gunbot.com",
      "description": "Gunbot is the leading crypto trading bot for popular exchanges like Binance, Bybit, Coinbase Advanced, Crypto.com, dYdX and more.",
      "publisher": {
        "@type": "ProfilePage",
        "name": "Gunbot.com"
      }
    },
    ldJson3: {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Gunbot",
      "url": "https://www.gunbot.com",
      "logo": "https://www.gunbot.com/logo192.png",
      "contactPoint": [{
        "@type": "ContactPoint",
        "contactType": "customer support",
        "areaServed": "Worldwide",
        "availableLanguage": ["English", "Spanish"],
        "email": "support@gunbot.freshdesk.com"
      }],
      "sameAs": [
        "https://medium.com/awesomegunbot", "https://gunthy.org", "https://twitter.com/gunbot_official", "https://www.youtube.com/@gunthardeniro"
      ]
    },
    ldJson4: {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "Gunbot",
      "url": "https://www.gunbot.com",
      "applicationCategory": "FinancialApplication",
      "operatingSystem": [
        "Windows",
        "MacOS",
        "Linux",
        "Raspberry Pi"
      ],
      "author": {
        "@type": "Organization",
        "name": "Gunbot",
        "url": "https://www.gunbot.com"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.6",
        "reviewCount": "124"
      },
      "softwareHelp": {
        "@type": "CreativeWork",
        "url": "https://www.gunbot.com/support/"
      },
      "softwareRequirements": "Requires an account on a supported cryptocurrency exchange",
      "description": "Gunbot is a cutting-edge trading bot for crypto enthusiasts, providing advanced automation for trading on multiple exchanges. It offers pre-tuned and configurable strategies, real-time control, and comprehensive support, ensuring a seamless and secure trading experience.",
      "softwareVersion": "v29.0",
      "datePublished": "2024-08-06",
      "downloadUrl": "https://www.gunbot.com/download/",
      "applicationSubCategory": "CryptoTrading",
      "screenshot": "https://www.gunbot.com/gunbotcom-x-preview.png"
    },
    ldJson5: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": "Gunbot Supported Exchanges",
      "description": "Gunbot supports a wide range of cryptocurrency exchanges, allowing users to execute automated trading strategies across multiple markets. This page lists all the exchanges supported by Gunbot, including both spot and futures trading.",
      "url": "https://www.gunbot.com/exchanges/",
      "numberOfItems": 20,
      "itemListOrder": "http://schema.org/ItemListOrderAscending",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Binance",
          "url": "https://www.binance.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✔️, Execute external alerts: ✔️, Supported derivatives: USDⓈ-M Futures, COIN-M Futures"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Binance US",
          "url": "https://www.binance.us/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "BingX",
          "url": "https://www.bingx.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✖️"
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "Bitfinex",
          "url": "https://www.bitfinex.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": "Bitget",
          "url": "https://www.bitget.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✔️, Execute external alerts: ✔️, Supported derivatives: USDT perpetual, Universal margin perpetual"
        },
        {
          "@type": "ListItem",
          "position": 6,
          "name": "Bitmex",
          "url": "https://www.bitmex.com/",
          "description": "Supported market types: Spot trading: ✖️, Futures trading: ✔️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 7,
          "name": "Bybit",
          "url": "https://www.bybit.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✔️, Execute external alerts: ✔️, Supported derivatives: USDT perpetuals, Inverse perpetuals"
        },
        {
          "@type": "ListItem",
          "position": 8,
          "name": "Crypto.com",
          "url": "https://crypto.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 9,
          "name": "CEX",
          "url": "https://cex.io/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 10,
          "name": "Coinbase Advanced Trade",
          "url": "https://coinbase.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 11,
          "name": "dYdX",
          "url": "https://dydx.exchange/",
          "description": "Supported market types: Spot trading: ✖️, Futures trading: ✔️, Execute external alerts: ✔️, Supported derivatives: Both v4 and v3 protocols are supported."
        },
        {
          "@type": "ListItem",
          "position": 12,
          "name": "Gate.io",
          "url": "https://www.gate.io/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 13,
          "name": "HitBTC",
          "url": "https://hitbtc.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 14,
          "name": "Huobi Global",
          "url": "https://www.huobi.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 15,
          "name": "Kraken",
          "url": "https://www.kraken.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✔️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 16,
          "name": "KuCoin",
          "url": "https://www.kucoin.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 17,
          "name": "MEXC",
          "url": "https://www.mexc.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 18,
          "name": "OKX",
          "url": "https://www.okx.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✔️, Execute external alerts: ✔️, Supported derivatives: Perpetual Swaps, Futures"
        },
        {
          "@type": "ListItem",
          "position": 19,
          "name": "Poloniex",
          "url": "https://poloniex.com/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        },
        {
          "@type": "ListItem",
          "position": 20,
          "name": "Bitstamp",
          "url": "https://www.bitstamp.net/",
          "description": "Supported market types: Spot trading: ✔️, Futures trading: ✖️, Execute external alerts: ✔️"
        }
      ]
    },
    ldJson6: {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": "How Gunbot Works",
      "description": "Learn how to get started with Gunbot and automate your cryptocurrency trading in five easy steps. This page provides quick instructions on installing Gunbot, connecting it to exchange accounts, selecting trading pairs, customizing strategies, and letting the bot handle trades.",
      "url": "https://www.gunbot.com/how-gunbot-works/",
      "step": [
        {
          "@type": "HowToStep",
          "name": "Get Gunbot Running in Minutes",
          "text": "Install Gunbot on your computer or VPS and access the user-friendly interface through your web browser.",
          "url": "https://www.gunbot.com/how-gunbot-works/"
        },
        {
          "@type": "HowToStep",
          "name": "Seamlessly Connect to Exchange",
          "text": "Enter your API key and secret to link Gunbot to your exchange account securely.",
          "url": "https://www.gunbot.com/how-gunbot-works/"
        },
        {
          "@type": "HowToStep",
          "name": "Choose Your Winning Pairs",
          "text": "Select coins to trade and set suitable strategies for each pair.",
          "url": "https://www.gunbot.com/how-gunbot-works/"
        },
        {
          "@type": "HowToStep",
          "name": "Customize Your Strategy",
          "text": "Modify trading strategies and set trading limits.",
          "url": "https://www.gunbot.com/how-gunbot-works/"
        },
        {
          "@type": "HowToStep",
          "name": "Relax While the Trading Bot Does the Work",
          "text": "Let Gunbot handle your trades and receive real-time notifications via Telegram.",
          "url": "https://www.gunbot.com/how-gunbot-works/"
        }
      ]
    },
    ldJson7: {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Gunbot",
      "url": "https://www.gunbot.com/features/",
      "description": "Discover all the features of Gunbot, including multi-platform support, unlimited trading pairs, powerful browser interface, and various trading strategies.",
      "image": "https://www.gunbot.com/logo192.png",
      "brand": {
        "@type": "Brand",
        "name": "Gunbot",
        "logo": "https://www.gunbot.com/logo192.png"
      },
      "offers": {
        "@type": "Offer",
        "url": "https://www.gunbot.com/plans/",
        "priceCurrency": "USD",
        "price": "199",
        "availability": "https://schema.org/InStock",
        "category": "Lifetime License",
        "priceValidUntil": "2024-12-30",
        "hasMerchantReturnPolicy": {
          "@type": "MerchantReturnPolicy",
          "applicableCountry": [
            "AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", 
            "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", 
            "SE", "SI", "SK", "US",  // EU countries + USA
            "CA", "AU", "NZ", "GB", "CH"  // Canada, Australia, New Zealand, UK, Switzerland
          ],
          "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
          "merchantReturnDays": 30,
          "returnMethod": "https://schema.org/ReturnByMail",
          "returnFees": "https://schema.org/FreeReturn"
        },
        "shippingDetails": {
          "@type": "OfferShippingDetails",
          "shippingRate": {
            "@type": "MonetaryAmount",
            "value": "0",
            "currency": "USD"
          },
          "deliveryTime": {
            "@type": "ShippingDeliveryTime",
            "businessDays": 0
          }
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.6",
        "reviewCount": "124"
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "url": "https://www.gunbot.com/features/"
      },
      "additionalType": [
        "https://schema.org/SoftwareApplication",
        "https://schema.org/FinancialProduct"
      ]
    }
    ,
    ldJson8: {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "Gunbot - DeFi Trading Bot",
      "url": "https://www.gunbot.com/defi-trading-bot/",
      "description": "Discover Gunbot, the ultimate trading bot with DeFi support. Works on a wide array of popular exchanges. Automate your cryptocurrency trading strategies with ease.",
      "operatingSystem": ["Windows", "MacOS", "Linux"],
      "applicationCategory": "FinancialApplication",
      "publisher": {
        "@type": "Organization",
        "name": "Gunbot",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.gunbot.com/logo192.png",
          "width": 192,
          "height": 192
        }
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "59",
        "url": "https://www.gunbot.com/plans/"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.6",
        "reviewCount": "124"
      },
      "softwareHelp": {
        "@type": "CreativeWork",
        "url": "https://www.gunbot.com/support/"
      },
      "softwareVersion": "v29.0",
      "datePublished": "2024-08-06",
      "downloadUrl": "https://www.gunbot.com/download/",
      "mainEntity": {
        "@type": "WebPageElement",
        "name": "Gunbot DeFi Trading Bot",
        "description": "Gunbot is a powerful tool for automating your DeFi trading strategies, supporting a variety of popular cryptocurrency exchanges. This page provides detailed information about its features and benefits."
      }
    },
    ldJson9: {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "url": "https://www.gunbot.com/devcommunity/",
      "name": "Gunbot Dev Community",
      "description": "Explore projects built by community members and learn about developing for the Gunbot platform. Realize your own advanced trading algorithms with extensive API integrations and powerful customization options.",
      "hasPart": [
        {
          "@type": "WebPage",
          "name": "ChannelMaestro",
          "description": "Allround trading strategy designed to be deployed in under 10 seconds.",
          "url": "https://www.gunbot.com/devcommunity/channelmaestro/"
        },
        {
          "@type": "WebPage",
          "name": "MachineGun",
          "description": "Subpercentage trading strategy for continual accumulation.",
          "url": "https://www.gunbot.com/devcommunity/machinegun/"
        },
        {
          "@type": "WebPage",
          "name": "Broxynet-V1",
          "description": "Trading modes optimized for returns with reduced risk.",
          "url": "https://www.gunbot.com/devcommunity/broxynet/"
        },
        {
          "@type": "WebPage",
          "name": "Quanta ExoTrader",
          "description": "Data science approach to trading the digital currency markets",
          "url": "https://www.gunbot.com/devcommunity/quantaexotrader/"
        },
        {
          "@type": "WebPage",
          "name": "Custom Strategies Examples",
          "description": "Overview of code examples for custom trading strategies.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/example-strategies/overview/"
        },
        {
          "@type": "WebPage",
          "name": "Exchanges and Timeframes",
          "description": "Detailed overview of supported timeframes per supported exchange.",
          "url": "https://www.gunbot.com/support/guides/trading-logic-and-optimization/timeframes-order-types-explained/"
        },
        {
          "@type": "WebPage",
          "name": "What, Custom Strategies?",
          "description": "Overview of how custom strategies work and their runtime environment.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/what-are-custom-strategies/"
        },
        {
          "@type": "WebPage",
          "name": "Methods and Modules",
          "description": "Documentation on the various functions and modules available for custom strategies.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/methods-modules/"
        },
        {
          "@type": "WebPage",
          "name": "Market Data",
          "description": "Explanation of the different types of market data available for custom strategies.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/market-data/"
        },
        {
          "@type": "WebPage",
          "name": "Visualize Strategy Targets",
          "description": "Guide on how to create visual representations of strategy targets.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/visualize-strategy-targets/"
        },
        {
          "@type": "WebPage",
          "name": "Display Custom Stats",
          "description": "Instructions on displaying custom statistics for strategies.",
          "url": "https://www.gunbot.com/support/docs/custom-strategies/display-custom-stats/"
        },
        {
          "@type": "WebPage",
          "name": "Autoconfig Settings Handling",
          "description": "Guide on controlling trading settings with autoconfig.",
          "url": "https://www.gunbot.com/support/guides/trading-logic-and-optimization/autoconfig-control-pair-settings/"
        },
        {
          "@type": "WebPage",
          "name": "Autoconfig Pair Management",
          "description": "Guide to adding and removing pairs using autoconfig.",
          "url": "https://www.gunbot.com/support/guides/trading-logic-and-optimization/autoconfig-adding-removing-pairs/"
        },
        {
          "@type": "WebPage",
          "name": "Gunbot Guru GPT",
          "description": "Access to Gunbot docs and code examples through a GPT interface.",
          "url": "https://chatgpt.com/g/g-9Q96nWnrs-gunbot-guru"
        }
      ],
      "publisher": {
        "@type": "Organization",
        "name": "Gunbot",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.gunbot.com/logo192.png",
          "width": 192,
          "height": 192
        }
      }
    },
    ldJson10: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Download Gunbot",
      "url": "https://www.gunbot.com/downloads/",
      "description": "Download Gunbot, the crypto trading bot, for your operating system.",
      "publisher": {
        "@type": "Organization",
        "name": "Gunbot",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.gunbot.com/logo192.png",
          "width": 192,
          "height": 192
        }
      }
    },
    ldJson11: {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Gunbot",
      "url": "https://www.gunbot.com/plans/",
      "logo": "https://www.gunbot.com/logo192.png",
      "image": "https://www.gunbot.com/gunbotcom-x-preview.png",
      "description": "Automated trading bot for cryptocurrency markets with different plans and pricing options.",
      "review": {
        "@type": "Review",
        "reviewBody": "Running so good out of the box i havent really looked into all switches and details",
        "author": {
          "@type": "Person",
          "name": "Jon"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        }
      },
      "brand": {
        "@type": "Brand",
        "name": "Gunbot"
      },
      "manufacturer": {
        "@type": "Organization",
        "name": "Gunthar de Niro S.L.R.",
        "logo": "https://www.gunbot.com/logo192.png",
        "url": "https://www.gunbot.com"
      }
    },
  },
  SEO: {
    "/": {
      title: "Next-Level Crypto Trading Bot: Gunbot | Official site",
      description: "Discover Gunbot, the user-friendly, self-hosted trading bot. Trade effortlessly on Binance, Bybit, Coinbase, dYdX and more. For spot, futures, and DeFi. Choose from lifetime or subscription plans."
    },
    "/features/": {
      title: "All Gunbot features - Detailed overview | Gunbot.com",
      description: "Learn about all Gunbot features. Trading automation tools with a strong focus on our community. Continuous development process, with open access to preview builds."
    },
    "/how-gunbot-works/": {
      title: "How Gunbot works, in 5 steps | Gunbot.com",
      description: "Learn how easy it is to get started with Gunbot. Installing is as simple as unpacking a zip file, trading works directly after you enter your exchange API key."
    },
    "/use-cases/": {
      title: "Different use cases for Gunbot | Gunbot.com",
      description: "Gunbot is an extremely versatily trading bot. Learn how a trading bot can help you improve the results of your trading. Did you think a trading bot is only about autonomously trading? Wrong."
    },
    "/live-demo/": {
      title: "Gunbot demo systems. Live trading & settings demo | Gunbot.com",
      description: "See how Gunbot works on a live demo system. The settings demo lets you try out all settings options. Monitor preconfigured bots: better than a software trial."
    },
    "/plans/": {
      title: "Gunbot plans & pricing overview  | Gunbot.com",
      description: "Subscribe, or buy a lifetime license for the best trading bot on the market. Professional support and software updates are included. Gunbot packages, add-ons & upgrades available."
    },
    "/plans/upgrade/": {
      title: "Gunbot upgrade options overview | Gunbot.com",
      description: "Compare all options to upgrade your Gunbot license. Each upgrade option includes customer support from the Gunbot Support team."
    },
    "/plans/upgrade/one-to-standard/": {
      title: "Upgrade Gunbot ONE to Standard - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot ONE to Gunbot Standard."
    },
    "/plans/upgrade/one-to-pro/": {
      title: "Upgrade Gunbot ONE to Pro - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot ONE to Gunbot Pro."
    },
    "/plans/upgrade/one-to-ultimate/": {
      title: "Upgrade Gunbot ONE to Ultimate - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot ONE to Gunbot Ultimate."
    },
    "/plans/upgrade/starter-to-standard/": {
      title: "Upgrade Gunbot Starter to Standard - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Starter to Gunbot Standard."
    },
    "/plans/upgrade/starter-to-pro/": {
      title: "Upgrade Gunbot Starter to Pro - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Starter to Gunbot Pro."
    },
    "/plans/upgrade/starter-to-ultimate/": {
      title: "Upgrade Gunbot Starter to Ultimate - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Starter to Gunbot Ultimate."
    },
    "/plans/upgrade/standard-to-pro/": {
      title: "Upgrade Gunbot Standard to Pro - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Standard to Gunbot Pro."
    },
    "/plans/upgrade/standard-to-ultimate/": {
      title: "Upgrade Gunbot Standard to Ultimate - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Standard to Gunbot Ultimate."
    },
    "/plans/upgrade/pro-to-ultimate/": {
      title: "Upgrade Gunbot Pro to Ultimate - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Pro to Gunbot Ultimate."
    },
    "/plans/upgrade/ultimate-to-unlimited-slots/": {
      title: "Upgrade Gunbot Ultimate (old) to Ultimate with unlimited slots and more - detailed info | Gunbot.com",
      description: "View all price and feature specifications for upgrading Gunbot Ultimate (old, 5 slots) to Gunbot Ultimate with unlimited slots."
    },
    "/contact/": {
      title: "Got questions? Just contact us | Gunbot.com",
      description: "Write us if you want to know more about Gunbot, we'll get back to you as soon as we can."
    },
    "/binance-profit-calculator/": {
      title: "Free Binance profit calculator | Gunbot.com",
      description: "✓ Keep track of all your trades & profits at Binance ✓ All in one sheet > Get your free copy now!"
    },
    "/features/custom-strategies/": {
      title: "Code custom trading strategies | Gunbot.com",
      description: "Use plain JavaScript to develop custom trading strategies. There is no Gunbot specific syntax, and you can bring your own node modules to use in your code"
    },
    "/plans/standard/": {
      title: "Gunbot Standard Edition - license details | Gunbot.com",
      description: "Detailed product information for Gunbot Standard. Includes: plug and play trading with stepGrid strategy, 20+ strategies for spot and futures"
    },
    "/plans/pro/": {
      title: "Gunbot Pro Edition - license details | Gunbot.com",
      description: "Detailed product information for Gunbot Pro. Includes: plug and play trading with stepGrid, 20+ strategies for spot and futures"
    },
    "/plans/ultimate/": {
      title: "Gunbot Ultimate Edition - license details | Gunbot.com",
      description: "Detailed product information for Gunbot Ultimate. Includes: 20+ strategies for spot and futures, custom JS strategies, TradingView add-on"
    },
    "/backtesting/": {
      title: "Backtesting Archive - Analyze Historical Performance with Gunbot",
      description: "Explore the backtesting archive on Gunbot. Filter through historical trading data, visualize performance metrics, and optimize your trading strategies. Access detailed backtest results and download settings."
    }
  },
  plans: {
    "Lifetime": [
      {
        name: "Standard",
        price: [`$ ${pricingData.promoStandard} /lifetime`],
        oldPrice: `$ 500`,
        description: "Everything you need to get serious about bot trading",
        buttonText: "Get Gunbot Standard",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["Lifetime license", `Unlike other bots, Gunbot is also available as a lifetime license without recurring costs.`],
          ["For 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["500 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens,
                 which need to be held in the wallet address you register after your purchase`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
                 Online guides help you to quickly set up up and configure your bot`],
          ["Chat & email support", `Get help whenever you need it. 
                 It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandard",
          //packageNameReadableBackup: <>Gunbot Standard<br />+ free upgrade to Pro‎</>,
          packageNameReadable: "Gunbot Standard",
          details: '/plans/standard/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStandard'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.promoPro} /lifetime`],
        oldPrice: `$ 900`,
        description: "Everything in Standard, plus backtesting and more exchange slots",
        buttonText: "Get Gunbot Pro",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["Lifetime license", `Unlike other bots, Gunbot is also available as a lifetime license without recurring costs. `],
          ["For 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Backtesting", `Includes a mode to backtest strategies in minute detail, even custom code or autoconfig behavior can be backtested.`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],

          ["750 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],

          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          //packageNameReadableBackup: <>Gunbot Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Gunbot Pro",
          details: '/plans/pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoPro'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featured: "Most Popular",
        //featuredPromo: '50% discount',
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.ultimate} /lifetime`],
        oldPrice: `$ 1400`,
        description: "🔥 DeFi support, no exchange limit, app, custom strategies and more",
        buttonText: "Get Gunbot Ultimate",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["Lifetime license", `Unlike other bots, Gunbot is also available as a lifetime license without recurring costs. `],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. 
          Choose between Binance, Binance US, dYdX, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Backtesting", `Includes a mode to backtest strategies in minute detail, even custom code or autoconfig behavior can be backtested.`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot.
                 Switching to another exchange or API key is easy and free`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                 You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                 This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
          This lets you forward test all kinds of settings before actually trading it.`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                 from adding pairs to changing strategy parameters`],
          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["2500 Gunthy tokens", `Gunbot uses a blockchain based license system. It uses a utility token called Gunthy 
                on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],

          /*["Market Maker trial", `Gunbot Market Maker is an additional module normally only available to Gunbot users who hold 24k+ Gunthy tokens. 
                With this promotion you get 30 days free access, without the token holding requirements.`]*/,
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          //packageNameReadableBackup: <>Gunbot Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Gunbot Ultimate",
          details: '/plans/ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/ultimate'
        },
        featuredPromo: '+ Free Exchange Slot worth $99',
      },
    ],
    "Monthly": [
      {
        name: "Standard",
        price: [`$ ${pricingData.monthlyStandard} /month`],
        //oldPrice: null,
        description: "Everything you need to get serious about bot trading",
        buttonText: "Get Gunbot Standard",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                 It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandard",
          //packageNameReadableBackup: <>Gunbot Standard<br />+ free upgrade to Pro‎</>,
          packageNameReadable: "Gunbot Standard",
          //details: '/plans/standard/',
          directLink: 'https://checkout.Gunbot.com/awesome/monthlyStandard'

        },
        featuredPromo: 'Good for trying',
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.monthlyPro} /month`],
        //oldPrice: `Ƀ ${round(proPrice, 4)}`,
        description: "Everything in Standard, and manage your bots on up to 3 exchanges",
        buttonText: "Get Gunbot Pro",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Backtesting", `Includes a mode to backtest strategies in minute detail, even custom code or autoconfig behavior can be backtested.`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          //packageNameReadableBackup: <>Gunbot Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Gunbot Pro",
          //details: '/plans/pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/monthlyPro'

        },
        featuredPromo: 'Good for trying',
        //featuredPromo: '50% discount',
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.monthlyUltimate} /month`],
        //oldPrice: `Ƀ ${round(ultimatePrice, 4)}`,
        description: "🔥 DeFi support, no exchange limit, app, custom strategies and more",
        buttonText: "Get Gunbot Ultimate",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges.
          Choose between Binance, Binance US, dYdX, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Backtesting", `Includes a mode to backtest strategies in minute detail, even custom code or autoconfig behavior can be backtested.`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot.
                 Switching to another exchange or API key is easy and free`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                 You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                 This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
          This lets you forward test all kinds of settings before actually trading it.`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                 from adding pairs to changing strategy parameters`],
          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],

          /*["Market Maker trial", `Gunbot Market Maker is an additional module normally only available to Gunbot users who hold 24k+ Gunthy tokens. 
                With this promotion you get 30 days free access, without the token holding requirements.`]*/,
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          //packageNameReadableBackup: <>Gunbot Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Gunbot Ultimate",
          //details: '/plans/ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/monthlyUltimate'
        },
        featuredPromo: 'Good for trying',
      },
    ],
    "Annual": [
      {
        name: "Standard",
        price: [`$ ${pricingData.yearlyStandard} /year`],
        annualPrice: `$ ${pricingData.yearlyStandard / 12} /month`,
        //oldPrice: null,
        description: "Everything you need to get serious about bot trading",
        buttonText: "Get Gunbot Standard",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                 It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "yearlyStandard",
          //packageNameReadableBackup: <>Gunbot Standard<br />+ free upgrade to Pro‎</>,
          packageNameReadable: "Gunbot Standard",
          //details: '/plans/standard/',
          directLink: 'https://checkout.Gunbot.com/awesome/yearlyStandard'

        },
        featuredPromo: `Save $ ${pricingData.yearlyStandard} with annual payment`,
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.yearlyPro} /year`],
        annualPrice: `$ ${pricingData.yearlyPro / 12} /month`,
        //oldPrice: `Ƀ ${round(proPrice, 4)}`,
        description: "Everything in Standard, and manage your bots on up to 3 exchanges",
        buttonText: "Get Gunbot Pro",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          //packageNameReadableBackup: <>Gunbot Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Gunbot Pro",
          //details: '/plans/pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/yearlyPro'

        },
        //featured: "Most Popular",
        featuredPromo: `Save $ ${pricingData.yearlyPro} with annual payment`,
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.yearlyUltimate} /year`],
        annualPrice: `$ ${pricingData.yearlyUltimate / 12} /month`,
        //oldPrice: `Ƀ ${round(ultimatePrice, 4)}`,
        description: "🔥 DeFi support, no exchange limit, app, custom strategies and more",
        buttonText: "Get Gunbot Ultimate",
        features: [
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. 
          Choose between Binance, Binance US, dYdX, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Unlimited gridbots", `You can run an unlimited number of limit order gridbots`],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot.
                 Switching to another exchange or API key is easy and free`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                 You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                 This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
          This lets you forward test all kinds of settings before actually trading it.`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                 from adding pairs to changing strategy parameters`],
          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["Telegram community", `The closed community of Gunbot users on Telegram is a great way to keep in touch with like minded Gunbot users.`],
          ["Gunbot Guides", `Learn how to get up and running with short guides. 
          Online guides help you to quickly set up up and configure your bot`], ["Chat & email support", `Get help  whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],

          /*["Market Maker trial", `Gunbot Market Maker is an additional module normally only available to Gunbot users who hold 24k+ Gunthy tokens. 
                With this promotion you get 30 days free access, without the token holding requirements.`]*/,
          ["For Windows, Mac, Linux & Raspberry Pi", `Gunbot is a locally installed application, it runs on Windows, Mac and Linux systems.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          //packageNameReadableBackup: <>Gunbot Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Gunbot Ultimate",
          //details: '/plans/ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/yearlyUltimate'
        },
        featuredPromo: `Save $ ${pricingData.yearlyUltimate} with annual payment`,
      },
    ],
    /*
    "Add-ons": [
      {
        name: "Backtesting add-on",
        price: [`Ƀ ${pricingData.promobacktesting.price}`, "", `Ƀ ${pricingData.promobacktesting.price}`],
        oldPrice: `Ƀ ${round(0.0036, 4)}`,
        description: "Backtest strategies for any  pair and any timeframe TradingView offers",
        features: [
          ["Backtest Gunbot strategies", `Emulate Gunbot strategies on any pair on Tradingview.com.
                  Most settings except grid, double Up, RT and TSA are supported`]
        ],
        url: {
          pathname: '/join/',
          packageName: "promobacktesting",
          packageNameReadable: "Backtesting add-on",
          directLink: 'https://checkout.Gunbot.com/awesome/promobacktesting'

        },
        featuredPromo: '50% discount',

      },
      {
        name: "TradingView add-on",
        price: [`Ƀ ${pricingData.tv.price}`, "", `Ƀ ${pricingData.tv.price}`],
        oldPrice: `Ƀ ${round(0.006, 4)}`,
        description: "Use Gunbot to execute trading alerts sent from a custom strategy at TradingView.",
        features: [
          ["Execute trades for incoming email alerts", `Execute trades for alerts from tradingview.com or any other email source.`],
          ["Requires Gunbot Standard", `Add-on can only be combined with Gunbot Standard or Pro - it's included in Gunbot Ultimate`]
        ],
        url: {
          pathname: '/join/',
          packageName: "tv",
          packageNameReadable: "TradingView add-on",
          directLink: 'https://checkout.Gunbot.com/awesome/tv'
        },
        featuredPromo: '50% discount',

      }
    ],
    */
    "Upgrades": [
      {
        name: "Extra exchange slot",
        price: [`$ 19 /month`],
        description: "Additional exchange API slot",
        features: [
          ["Monthy subscription", `This is an upgrade option to get an extra exchange slot for your lifetime Gunbot license, for a limited period of time. It is possible to buy multiple additions for more than one extra exchange slot.`],
          ["Run multiple bots on different API keys of the same exchange", `Normally Gunbots API slots limit trading to one API key per unique exchange, this extension allows for a second API key of one exchange, you can pick the exchange during the order.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "extraSlotMonthly",
          packageNameReadable: "Upgrade Standard to Pro",
          //details: '/plans/upgrade/standard-to-pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/extraSlotMonthly'
        },
        //featuredPromo: '50% discount',
      },
      {
        name: "Extra exchange slot",
        price: [`$ 99 /lifetime`],
        description: "Additional exchange API slot",
        features: [
          ["Lifetime extension", `This is an upgrade option to get an extra exchange slot for your lifetime Gunbot license, forever. It is possible to buy multiple additions for more than one extra exchange slot.`],
          ["Run multiple bots on different API keys of the same exchange", `Normally Gunbots API slots limit trading to one API key per unique exchange, this extension allows for a second API key of one exchange, you can pick the exchange during the order.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "extraSlot",
          packageNameReadable: "Upgrade Standard to Pro",
          //details: '/plans/upgrade/standard-to-pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/extraSlot'

        },
        //featuredPromo: '50% discount',
      },
      {
        name: "Standard to Pro",
        price: [`$ ${pricingData.promoStandardTopromoPro} /lifetime`],
        description: "Upgrade from Gunbot Standard to Gunbot Pro",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. Has unlimited API slots`],
          ["250 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],

        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandardTopromoPro",
          packageNameReadableBackup: <>Upgrade Standard to Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Upgrade Standard to Pro",
          details: '/plans/upgrade/standard-to-pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStandardTopromoPro'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },

      {
        name: "Standard to Ultimate",
        price: [`$ ${pricingData.promoStandardToUltimate} /lifetime`],
        description: "Upgrade from Gunbot Standard to Gunbot Ultimate",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. Has unlimited API slots`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["2000 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
         This lets you forward test all kinds of settings before actually trading it.`],

          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandardToUltimate",
          packageNameReadableBackup: <>Upgrade Standard to Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Upgrade Standard to Ultimate",
          details: '/plans/upgrade/standard-to-ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStandardToUltimate'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '75% discount',
      },
      {
        name: "Pro to Ultimate",
        price: [`$ ${pricingData.promoProToUltimate} /lifetime`],
        description: "Upgrade from Gunbot Pro to Gunbot Ultimate",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. Has unlimited API slots`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["1750 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
         This lets you forward test all kinds of settings before actually trading it.`],
          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoProToUltimate",
          packageNameReadableBackup: <>Upgrade Pro to Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Upgrade Pro to Ultimate",
          details: '/plans/upgrade/pro-to-ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoProToUltimate'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '75% discount',
      },
      {
        name: "promoUltimate to Ultimate",
        price: [`$ ${pricingData.promoUltimateToUltimate} /lifetime`],
        description: "Upgrade from 5 slot Ultimate to Gunbot Ultimate with unlimited slots",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["Unlimited API slots", `Use Gunbot to trade simultaneously on all supported exchanges. Has unlimited API slots`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["1250 Gunthy tokens", `Gunbot uses a blockchain based license system. 
          It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
          which need to be held in the wallet address you register after your purchase`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
          This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
   This lets you forward test all kinds of settings before actually trading it.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoUltimateToUltimate",
          packageNameReadableBackup: <>Upgrade Ultimate (old) to Ultimate with unlimited API slots</>,
          packageNameReadable: "promoUltimate (5 slots) to Ultimate",
          details: '/plans/upgrade/ultimate-to-unlimited-slots/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoUltimateToUltimate'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '75% discount',
      },
      {
        name: "One to Standard",
        price: [`$ ${pricingData.oneTopromoStandard} /lifetime`],
        description: "Upgrade from Gunbot ONE to Gunbot Standard",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["500 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`]
        ],
        url: {
          pathname: '/join/',
          packageName: "oneTopromoStandard",
          packageNameReadableBackup: <>Upgrade Starter to Standard<br />+ free upgrade to Pro‎</>,
          packageNameReadable: "Upgrade Gunbot ONE to Gunbot Standard",
          details: '/plans/upgrade/one-to-standard/',
          directLink: 'https://checkout.Gunbot.com/awesome/oneTopromoStandard'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },
      {
        name: "One to Pro",
        price: [`$ ${pricingData.oneTopromoPro} /lifetime`],
        description: "Upgrade from Gunbot ONE to Gunbot Pro",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],

          ["750 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],


        ],
        url: {
          pathname: '/join/',
          packageName: "oneTopromoPro",
          packageNameReadableBackup: <>Upgrade One to Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Upgrade One to Pro",
          details: '/plans/upgrade/one-to-pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/oneTopromoPro'
        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },
      {
        name: "One to Ultimate",
        price: [`$ ${pricingData.oneToUltimate} /lifetime`],
        description: "Upgrade from Gunbot ONE to Gunbot Ultimate",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. 
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot.
                 Switching to another exchange or API key is easy and free`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                 You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                 This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
          This lets you forward test all kinds of settings before actually trading it.`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                 from adding pairs to changing strategy parameters`],

          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["2500 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],
        ],
        url: {
          pathname: '/join/',
          packageName: "oneToUltimate",
          packageNameReadableBackup: <>Upgrade Starter to Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Upgrade Starter to Ultimate",
          details: '/plans/upgrade/one-to-ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/oneToUltimate'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '75% discount',
      },
      {
        name: "Starter to Standard",
        price: [`$ ${pricingData.promoStarterTopromoStandard} /lifetime`],
        description: "Upgrade from Gunbot Starter to Gunbot Standard",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],
          ["300 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`]
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterTopromoStandard",
          packageNameReadableBackup: <>Upgrade Starter to Standard<br />+ free upgrade to Pro‎</>,
          packageNameReadable: "Upgrade Starter to Standard",
          details: '/plans/upgrade/starter-to-standard/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStarterTopromoStandard'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },

      {
        name: "Starter to Pro",
        price: [`$ ${pricingData.promoStarterTopromoPro} /lifetime`],
        description: "Upgrade from Gunbot Starter to Gunbot Pro",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot. 
                Switching to another exchange or API key is easy and free`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                from adding pairs to changing strategy parameters`],

          ["550 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],


        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterTopromoPro",
          packageNameReadableBackup: <>Upgrade Starter to Pro<br />+ free upgrade to Ultimate</>,
          packageNameReadable: "Upgrade Starter to Pro",
          details: '/plans/upgrade/starter-to-pro/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStarterTopromoPro'
        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '50% discount',
      },
      {
        name: "Starter to Ultimate",
        price: [`$ ${pricingData.promoStarterToUltimate} /lifetime`],
        description: "Upgrade from Gunbot Starter to Gunbot Ultimate",
        features: [
          ["Lifetime", `This is an upgrade option for existing users with a lifetime plan`],
          ["DeFi trading 🔥", `Take control of your funds, even when the exchange disappears. Supports trading on DeFi platforms, currently dYdX exchange is supported with more decentralized platforms coming soon!`],
          ["Spot & futures trading", `Optimized for trading on spot and futures markets`],
          ["For all exchanges", `Use Gunbot to trade simultaneously on all supported exchanges. 
          Choose between Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Free extra exchange slot ⚡', "Bitcoin halving season promotion: get a free additional exchange slot. This means you can use Gunbot on, for example, a second Binance account."],
          ["Unlimited trading pairs", `No limit on the number of trading pairs, 
                like USD-BTC or BTC-LTC, you can simultaneously trade`],
          ["Ready-made strategies", `Includes all native Gunbot strategies, they are fully customizable!`],
          ["Switch exchange anytime", `Thanks to the API slot system you always control on which exchange you use your bot.
                 Switching to another exchange or API key is easy and free`],
          ["Custom JS strategies", `Code your own custom strategies using JavaScript, without any Gunbot specific syntax. 
                 You can even bring your own NPM modules, connect to external data sources. Easy to use trading methods.`],
          ["Mobile app", `Monitor all your bot instances with the Gunbot X app for Android and iOS.
                 This is the quickest and easiest way to keep track of your bots`],
          ["Gunbot Simulator", `Run real strategies on real market data, with fake funds.
          This lets you forward test all kinds of settings before actually trading it.`],
          ["AutoConfig", `A unique feature that lets you automate config changes 
                 from adding pairs to changing strategy parameters`],

          ["TradingView add-on", `Use Gunbot to execute trading alerts sent from a custom strategy at TradingView. 
                It works by listening to incoming emails or webhooks from TradingView, when an alert comes in Gunbot will immediately execute it`],
          ["2300 Gunthy tokens", `Gunbot uses a blockchain based license system. 
                It uses a utility token called Gunthy on the Ethereum blockchain. Each license from Standard comes with a free number of tokens, 
                which need to be held in the wallet address you register after your purchase`],
          ["Chat & email support", `Get help whenever you need it. 
                It doesn't matter if you have a basic setup problem or an advanced strategy question, you'll get help from the Gunbot Support team`],

        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterToUltimate",
          packageNameReadableBackup: <>Upgrade Starter to Ultimate<br />+ 30 days Market Maker trial</>,
          packageNameReadable: "Upgrade Starter to Ultimate",
          details: '/plans/upgrade/starter-to-ultimate/',
          directLink: 'https://checkout.Gunbot.com/awesome/promoStarterToUltimate'

        },
        featuredPromo: '+ Free Exchange Slot worth $99',
        //featuredPromo: '75% discount',
      },

    ],
    "Strats": [
      {
        name: "Quanta exoTrader",
        price: [`$ 200 /lifetime`],
        //oldPrice: `$ 200`, // can be used to highlight discounts
        description: "Custom trading strategy",
        buttonText: "Get exoTrader",
        author: '@flightcommander',
        features: [
          ["Spot Strategy", `This strategy is for use on spot exchanges and dYdX longs only.`],
          ["For Gunbot Standard or higher", `This paid strategy can be used on Gunbot Standard or higher.`],
        ],
        url: {
          packageName: "quantaexotrader",
          packageNameReadable: "Quanta exoTrader",
          directLink: 'https://checkout.gunbot.com/quantatrading/quantaexotrader'
        },
      },
      /*
      {
        name: "machineGun Subscription",
        visibleName: "machineGun v5",
        price: [`$ 20 /month`],
        oldPrice: `$ 25`,
        description: "machineGun is a subpercentage trading strategy focused on continual accumulation to lower overall unit cost",
        buttonText: "Get machineGun!",
        author: '@TheWhiteGoblin @BeerK0in',
        features: [
          ["One & Done Setting", `machineGun only requires the user to enter one setting before it can run`],
          ["30+ Market Protections", `machineGun has over thirty features and market protection mechanisms`],
          ["Conquer Spot Markets", `machineGun v5 is designed to conquer spot exchanges and markets`],
          ["For Standard & Above", `machineGun can be used with any standard professional or ultimate license of Gunbot`],
        ],
        url: {
          packageName: "machinegun",
          packageNameReadable: "machineGun",
          directLink: 'https://checkout.gunbot.com/goblin/monthlymachinegun'
        },
      },
      */
      {
        name: "machineGun Lifetime",
        visibleName: "machineGun v5",
        price: [`$ 200 /lifetime`],
        //oldPrice: `$ 250`,
        description: "machineGun is a subpercentage trading strategy focused on continual accumulation to lower overall unit cost",
        buttonText: "Get machineGun!",
        author: '@TheWhiteGoblin @BeerK0in',
        features: [
          ["One & Done Setting", `machineGun only requires the user to enter one setting before it can run`],
          ["30+ Market Protections", `machineGun has over thirty features and market protection mechanisms`],
          ["Conquer Spot Markets", `machineGun v5 is designed to conquer spot exchanges and markets`],
          ["For Standard & Above", `machineGun can be used with any standard professional or ultimate license of Gunbot`],
        ],
        url: {
          packageName: "machinegun",
          packageNameReadable: "machineGun",
          directLink: 'https://checkout.gunbot.com/goblin/machinegun'
        },
      },
      {
        name: "ChannelMaestro Subscription",
        visibleName: "ChannelMaestro",
        price: [`$ 15 /month`],
        //oldPrice: `$ 500`, // can be used to highlight discounts
        description: "Custom trading strategy",
        buttonText: "Get ChannelMaestro",
        author: '@boekenbox',
        features: [
          ["Spot strategy", `This strategy is for use on spot exchanges only`],
          ["For Gunbot Standard or higher", `This paid strategy can be used on Gunbot Standard or higher`],
        ],
        url: {
          packageName: "channelmaestro",
          packageNameReadable: "ChannelMaestro",
          directLink: 'https://checkout.gunbot.com/awesome/monthlyChannelmaestro'
        },
      },
      {
        name: "ChannelMaestro",
        price: [`$ 200 /lifetime`],
        //oldPrice: `$ 500`, // can be used to highlight discounts
        description: "Custom trading strategy",
        buttonText: "Get ChannelMaestro",
        author: '@boekenbox',
        features: [
          ["Spot strategy", `This strategy is for use on spot exchanges only`],
          ["For Gunbot Standard or higher", `This paid strategy can be used on Gunbot Standard or higher`],
        ],
        url: {
          packageName: "channelmaestro",
          packageNameReadable: "ChannelMaestro",
          directLink: 'https://checkout.gunbot.com/awesome/channelmaestro'
        },
      },
      {
        name: "Broxynet-V1",
        price: [`$ 150 /lifetime`],
        //oldPrice: ``, // can be used to highlight discounts
        description: "Custom trading strategy",
        buttonText: "Get Broxynet",
        author: '@Steven0_0smith',
        features: [
          ["Spot strategy", `This strategy is for use on spot exchanges only`],
          ["For Gunbot Standard or higher", `This paid strategy can be used on Gunbot Standard or higher`],
        ],
        url: {
          packageName: "Broxynet-v1",
          packageNameReadable: "Broxynet-V1",
          directLink: 'https://checkout.gunbot.com/steven0_0smith/broxynet'
        },
      },
      {
        name: "GTRENDFUTURES Strategy",
        price: [`$ 350 /lifetime`],
        //oldPrice: `$ 499`, // can be used to highlight discounts
        description: "AutoConfig Strategy which uses StepgridHedge as backbone for Hedging entries",
        buttonText: "Get GTREND FUTURES Strategy",
        author: '@luis_gandara',
        features: [
          ["Futures strategy", ` Futures Strategy currently only bybit/bitget/okx future exchanges will be added included `],
          ["Autoconfig based", "AutoConfig Strategy which uses StepgridHedge as backbone for Hedging entries"],
          ["For Gunbot Standard or higher", `Its a Trending DCAing / Hedging strategy Suppor/recistance / Candles Multitimeframe...`],
        ],
        url: {
          packageName: "gtrendfutures",
          packageNameReadable: "GTRENDFUTURES",
          directLink: 'https://checkout.gunbot.com/luis/gtrendfutures'
        },
      },
      {
        name: "GTRENDSPOT Strategy",
        price: [`$ 200 /lifetime`],
        //oldPrice: `$ 399`, // can be used to highlight discounts
        description: "AutoConfig Spot Strategy which uses Stepgridscalp/Gtepgrid/Gain/Gridbot BUY/SELL Method To combine into a Powerful dumps/pumps Trader to Preserve assets and utilize with more presicion on uptrends for better Gains",
        buttonText: "Get GTRENDSPOT strategy",
        author: '@luis_gandara',
        features: [
          ["Spot strategy", `Spot Automated Algorithm Simple to use and setup with hundreds of AC background jobs to ride dumps and pumps to get best posible profits and entries from the market`],
          ["Autoconfig based", "AutoConfig Spot Strategy which uses Stepgridscalp/Gtepgrid/Gain/Gridbot BUY/SELL Method To combine into a Powerful dumps/pumps Trader to Preserve assets and utilize with more presicion on uptrends for better Gains"],
          ["For Gunbot Standard or higher", `Strategy Automated Algorithm preserve assets on dumps and get better Tradinglimits entries... / 2 setings easy to setup everything and start trading`],
        ],
        url: {
          packageName: "gtrendspot",
          packageNameReadable: "GTRENDSPOT",
          directLink: 'https://checkout.gunbot.com/luis/gtrendspot'
        },
      },
    ],

  },
  esPlans: {
    "De por vida": [
      {
        name: "Standard",
        price: [`$ ${pricingData.promoStandard} / De por vida`],
        oldPrice: `$ 500`,
        description: "Todo lo que necesita para tomarse el trading con bots en serio, para todos los bolsillos",
        buttonText: "Obtenga Gunbot Standard",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Licencia de por vida", `A diferencia de otros bots, Gunbot está disponible como licencia de por vida sin costes recurrentes.`],
          ["Para 1 exchange", `Utilice Gunbot para operar en un exchange soportado. Dispone de 1 ranura API.
          Elija entre Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, Bitmex, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
          tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", ` 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["500 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
                        Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                   No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandard",
          packageNameReadable: "Gunbot Standard",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStandard'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.promoPro} / De por vida`],
        oldPrice: `$ 900`,
        description: "Todo lo incluido en Standard, y permite operar con sus bots hasta en 3 exchanges.",
        buttonText: "Obtenga Gunbot Pro",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Licencia de por vida", `A diferencia de otros bots, Gunbot está disponible como licencia de por vida sin costes recurrentes.`],
          ["Para 3 exchanges", `Utilice Gunbot para operar simultáneamente en 3 exchanges soportados. Tiene 3 ranuras API
          Elija entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, Bitmex, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],

          ["750 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia permanente desde Standard hasta Ultimate viene con un número especifico de tokens gratuitos,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],

          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          packageNameReadable: "Gunbot Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoPro'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.ultimate} / De por vida`],
        oldPrice: `$ 1400`,
        description: "🔥 Soporte DeFi, sin límite de Exchanges, aplicación para móviles, estrategias personalizadas y mucho más",
        buttonText: "Obtenga Gunbot Ultimate",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Licencia de por vida", `A diferencia de otros bots, Gunbot está disponible como licencia de por vida sin costes recurrentes.`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. 
          Escoja entre Binance, Binance US, dYdX, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitmex, Bitfinex, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot.
                 Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                 Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                 Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
          Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                 desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["2500 tokens Gunthy", `Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia permanente desde Standard hasta Ultimate viene con un número especifico de tokens gratuitos,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          packageNameReadable: "Gunbot Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/ultimate'
        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
    ],
    "Mensual": [
      {
        name: "Standard",
        price: [`$ ${pricingData.monthlyStandard} / Al mes`],
        description: "Todo lo que necesita para tomarse el trading con bots en serio, para todos los bolsillos",
        buttonText: "Obtenga Gunbot Standard",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 1 exchange", `Utilice Gunbot para operar en un exchange soportado. Dispone de 1 ranura API.
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, Bitmex, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                 No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandard",
          packageNameReadable: "Gunbot Standard",
          directLink: 'https://checkout.Gunbot.com/crazymop/monthlyStandard'
        },
        featuredPromo: 'Bueno para probar',
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.monthlyPro} / Al mes`],
        description: "Todo lo incluido en Standard, y permite operar con sus bots hasta en 3 exchanges.",
        buttonText: "Obtenga Gunbot Pro",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 3 exchanges", `Utilice Gunbot para operar en tres exchanges soportados. Dispone de 3 ranuras API
          Escoja entre Binance, Binance US, Huobi Global, 
          OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          packageNameReadable: "Gunbot Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/monthlyPro'

        },
        featuredPromo: 'Bueno para probar',
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.monthlyUltimate} / Al mes`],
        description: "🔥 Soporte DeFi, sin límite de cambio, aplicación para móviles, estrategias personalizadas y mucho más",
        buttonText: "Obtenga Gunbot Ultimate",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados.
          Escoja entre Binance, Binance US, dYdX, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot.
                 Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin ninguna sintaxis específica de Gunbot. 
          Puede incluso traer sus propios módulos NPM, conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
          ["aplicación para móviles<", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                 Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
          Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                 desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          packageNameReadable: "Gunbot Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/monthlyUltimate'
        },
        featuredPromo: 'Bueno para probar',
      },
    ],
    "Anual": [
      {
        name: "Standard",
        price: [`$ ${pricingData.yearlyStandard} / Al año`],
        annualPrice: `$ ${pricingData.yearlyStandard / 12} / Al mes`,
        description: "Todo lo que necesita para tomarse el trading con bots en serio, para todos los bolsillos",
        buttonText: "Obtenga Gunbot Standard",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                 No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "yearlyStandard",
          packageNameReadable: "Gunbot Standard",
          directLink: 'https://checkout.Gunbot.com/crazymop/yearlyStandard'

        },
        featuredPromo: `Ahorre ${pricingData.yearlyStandard} USD con el pago anual`,
      },
      {
        name: "Pro",
        price: [`$ ${pricingData.yearlyPro} / Al año`],
        annualPrice: `$ ${pricingData.yearlyPro / 12} / Al mes`,
        description: "Todo lo incluido en Standard, y permite operar con sus bots hasta en 3 exchanges.",
        buttonText: "Obtenga Gunbot Pro",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 3 exchanges", `Utilice Gunbot para operar en tres exchanges soportados. Dispone de 3 ranuras API
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoPro",
          packageNameReadable: "Gunbot Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/yearlyPro'

        },
        featuredPromo: `Ahorre ${pricingData.yearlyPro} USD con el pago anual`,
      },
      {
        name: "Ultimate",
        price: [`$ ${pricingData.yearlyUltimate} / Al año`],
        annualPrice: `$ ${pricingData.yearlyUltimate / 12} / Al mes`,
        description: "🔥 Soporte DeFi, sin límite de cambio, aplicación para móviles, estrategias personalizadas y mucho más",
        buttonText: "Obtenga Gunbot Ultimate",
        features: [
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. 
          Escoja entre Binance, Binance US, dYdX, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ["Gridbots ilimitados", `Puedes ejecutar un número ilimitado de gridbots operando con órdenes límite`],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot.
                 Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                 Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
          Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
          Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                 desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["Comunidad de Telegram", `La comunidad privada de usuarios de Gunbot en Telegram es una forma estupenda de mantener el contacto con usuarios de Gunbot afines.`],
          ["Guías para Gunbot", `Iníciese con facilidad gracias a nuestras guías rápidas. 
          Las guías en línea le ayudarán a instalar y configurar rápidamente su bot`], ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["Para Windows, Mac, Linux y Raspberry Pi", `Gunbot se instala localmente y funciona en sistemas Windows, Mac y Linux.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "ultimate",
          packageNameReadable: "Gunbot Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/yearlyUltimate'
        },
        featuredPromo: `Ahorre ${pricingData.yearlyUltimate} USD con el pago anual`,
      },
    ],
    "Upgrades": [
      {
        name: "Ranura API de exchange adicional",
        price: [`$ 19 / Al mes`],
        description: "Ranura API de exchange adicional",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Suscripción mensual", `Esta es una opción de actualización para obtener una ranura de intercambio adicional para su suscripción a Gunbot. Por un período de tiempo limitado. Es posible adquirir varias para obtener más de una ranura adicional.`],
          ["Ejecute múltiples bots con diferentes claves API en el mismo exchange", `Normalmente las ranuras API de Gunbots limitan el trading a una clave API por cada exchange, esta extensión permite una segunda clave API para cualquier exchange que usted seleccione.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "extraSlotMonthly",
          packageNameReadable: "Upgrade Standard a Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/extraSlotMonthly'
        },
      },
      {
        name: "Ranura API de exchange adicional",
        price: [`$ 99 / De por vida`],
        description: "Ranura API de exchange adicional",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Complemento para la licencia de por vida", `Esta es una opción de actualización para obtener una ranura de intercambio adicional, para siempre en su licencia de Gunbot de por vida. Es posible adquirir varias para obtener más de una ranura adicional.`],
          ["Ejecute múltiples bots con diferentes claves API en el mismo exchange", `Normalmente las ranuras API de Gunbots limitan el trading a una clave API por cada exchange, esta extensión permite una segunda clave API para cualquier exchange que usted seleccione.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "extraSlot",
          packageNameReadable: "Upgrade Standard a Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/extraSlot'

        },
      },
      {
        name: "Standard a Pro",
        price: [`$ ${pricingData.promoStandardTopromoPro} / De por vida`],
        description: "Actualización de Gunbot Standard a Gunbot Pro",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["De por vida", `Esta es una opción de actualización para los usuarios existentes con una licencia de por vida.`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. Has unlimited API slots`],
          ["250 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],

        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandardTopromoPro",
          packageNameReadable: "Upgrade Standard a Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStandardTopromoPro'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },

      {
        name: "Standard a Ultimate",
        price: [`$ ${pricingData.promoStandardToUltimate} / De por vida`],
        description: "Actualización de Gunbot Standard a Gunbot Ultimate",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. Has unlimited API slots`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["2000 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
         Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],

          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStandardToUltimate",
          packageNameReadable: "Upgrade Standard a Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStandardToUltimate'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Pro a Ultimate",
        price: [`$ ${pricingData.promoProToUltimate} / De por vida`],
        description: "Actualización de Gunbot Pro a Gunbot Ultimate",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. Has unlimited API slots`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["1750 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
         Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoProToUltimate",
          packageNameReadable: "Upgrade Pro a Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoProToUltimate'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "promoUltimate a Ultimate",
        price: [`$ ${pricingData.promoUltimateToUltimate} / De por vida`],
        description: "Actualización de 5 ranuras Ultimate a Gunbot Ultimate con ranuras ílimitadas",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Unlimited API slots", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. Has unlimited API slots`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["1250 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
          Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
           que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
          Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
   Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "promoUltimateToUltimate",
          packageNameReadable: "promoUltimate (5 slots) a Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoUltimateToUltimate'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "One a Standard",
        price: [`$ ${pricingData.oneTopromoStandard} / De por vida`],
        description: "Actualización para Gunbot ONE a Gunbot Standard",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["500 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`]
        ],
        url: {
          pathname: '/join/',
          packageName: "oneTopromoStandard",
          packageNameReadable: "Upgrade Gunbot ONE a Gunbot Standard",
          directLink: 'https://checkout.Gunbot.com/crazymop/oneTopromoStandard'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "One a Pro",
        price: [`$ ${pricingData.oneTopromoPro} / De por vida`],
        description: "Actualización para Gunbot ONE a Gunbot Pro",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],

          ["750 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],


        ],
        url: {
          pathname: '/join/',
          packageName: "oneTopromoPro",
          packageNameReadable: "Upgrade One a Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/oneTopromoPro'
        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "One a Ultimate",
        price: [`$ ${pricingData.oneToUltimate} / De por vida`],
        description: "Actualización para Gunbot ONE a Gunbot Ultimate",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. 
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot.
                 Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                 Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                 Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
          Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                 desde añadir pares hasta cambiar los parámetros de la estrategia`],

          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["2500 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],
        ],
        url: {
          pathname: '/join/',
          packageName: "oneToUltimate",
          packageNameReadable: "Upgrade Starter a Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/oneToUltimate'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Starter a Standard",
        price: [`$ ${pricingData.promoStarterTopromoStandard} / De por vida`],
        description: "Actualización para Gunbot Starter a Gunbot Standard",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 1 exchange", `Use Gunbot to trade on one supported exchange. Has 1 API slot.
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],
          ["300 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`]
        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterTopromoStandard",
          packageNameReadable: "Upgrade Starter to Standard",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStarterTopromoStandard'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Starter a Pro",
        price: [`$ ${pricingData.promoStarterTopromoPro} / De por vida`],
        description: "Actualización para Gunbot Starter a Gunbot Pro",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para 3 exchanges", `Use Gunbot to trade simultaneously on 3 supported exchanges. Has 3 API slots
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot. 
                Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                desde añadir pares hasta cambiar los parámetros de la estrategia`],

          ["550 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],


        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterTopromoPro",
          packageNameReadable: "Upgrade Starter a Pro",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStarterTopromoPro'
        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
      {
        name: "Starter a Ultimate",
        price: [`$ ${pricingData.promoStarterToUltimate} / De por vida`],
        description: "Actualización para Gunbot Starter a Gunbot Ultimate",
        buttonText: "¡Lo quiero ya!",
        features: [
          ["Licencia de por vida", `Esta es una opción de actualización para los usuarios existentes con un plan de por vida`],
          ["Operar en DeFi 🔥", `Tome el control de sus fondos, incluso cuando el exchange desaparece. Soporta operar en plataformas DeFi, actualmente dYdX exchange es compatible, con más plataformas descentralizadas disponibles próximamente.!`],
          ["Opere en Spot y futuros", `Optimizado para operar en los mercados spot y de futuros`],
          ["Para todos los exchanges", `Utilice Gunbot para operar simultáneamente en todas los exchanges soportados. 
          Escoja entre Binance, Binance US, Huobi Global, 
            OKX, Crypto.com, Bybit, Bitstamp, 
          Binance Futures, HitBTC, Kraken Futures, KuCoin, 
          MEXC, BingX, Bitget, Bitfinex, BitMEX, CEX, Coinbase Advanced, 
          Kraken, Poloniex, Gate.io`],
          ['Ranura de exchange extra gratuita ⚡', "Promoción por el Halving de Bitcoin: obtenga sin coste un espacio adicional para su exchange favorito, por ejemplo permitiéndole usar Gunbot también en una segunda cuenta de Binance."],
          ["Pares de trading ilimitados", `Sin límite en el número de pares de trading, 
                tales como USD-BTC o BTC-LTC, puede tradear simultáneamente`],
          ["Estrategias listas para usar", `Incluye todas las estrategias nativas de Gunbot, ¡son totalmente personalizables!`],
          ["Cambie de exchange en cualquier momento", `Gracias al sistema de ranuras API siempre controla en qué exchange utiliza su bot.
                 Cambiar a otro exchange o clave API es fácil y gratuito`],
          ["Estrategias personalizadas de JS", `Codifique sus propias estrategias personalizadas utilizando JavaScript, sin necesidad de utilizar sintaxis específica de Gunbot. 
                 Puede incluso traer sus propios módulos NPM y conectarse a fuentes de datos externas. Métodos de trading fáciles de usar.`],
          ["aplicación para móviles", `Supervise todas sus instancias de bot con la aplicación Gunbot X para Android e iOS.
                 Esta es la forma más rápida y sencilla de hacer el seguimiento de sus bots`],
          ["Simulador de Gunbot", `Ejecute estrategias reales con datos de mercado reales y fondos ficticios.
          Esto le permite probar todo tipo de configuraciones antes de operar con fondos de verdad.`],
          ["AutoConfig", `Una función única que le permite automatizar los cambios de configuración 
                 desde añadir pares hasta cambiar los parámetros de la estrategia`],

          ["Complemento de TradingView", `Use Gunbot para ejecutar alertas de trading enviadas desde una estrategia personalizada en TradingView. 
                Funciona escuchando los correos electrónicos entrantes o webhooks de TradingView, cuando llega una alerta Gunbot la ejecutará de inmediato`],
          ["2300 Tokens Gunthy", `Gunbot utiliza un sistema de licencias basado en blockchain. 
                Utiliza un token de utilidad llamado Gunthy en la blockchain de Ethereum. Cada licencia de Standard viene con un número gratuito de tokens,
                 que deben mantenerse en la dirección del monedero que registre después de su compra.`],
          ["Asistencia por correo electrónico y chat", `Obtenga ayuda cuando la necesite. 
                No importa si tiene un problema de configuración básica o una pregunta de estrategia avanzada, el equipo de soporte de Gunbot le ayudará.`],

        ],
        url: {
          pathname: '/join/',
          packageName: "promoStarterToUltimate",
          packageNameReadable: "Upgrade Starter a Ultimate",
          directLink: 'https://checkout.Gunbot.com/crazymop/promoStarterToUltimate'

        },
        featuredPromo: '+ RANURA EXTRA GRATIS - VALOR 99 USD',
      },
    ],
  },
  bots: {
    '5052': ['USDT-BTC',
      'USDT-ETH',
      'USDT-BNB',
      'USDT-IO',
      'USDT-SUPER',
      'USDT-DOGE',
      'USDT-ADA',
      'USDT-PEOPLE',
      'USDT-DOT'
    ],
    '5053': ['BTC-ETH',
      'BTC-SOL',
      'BTC-DOGE',
      'BTC-ADA',
      'BTC-AVAX',
      'BTC-TRX',
      'BTC-NEAR',
      'BTC-WIF',
      'BTC-BNB']
  },
  

}

/*
if (isVariation) {
  allPlans.plans["Lifetime"].forEach((element, index) => {
    if (index < 4) {
      allPlans.plans["Lifetime"][index].features.push(["Money back guarantee", "Not happy for any reason? We'll still try to help you, or issue a prompt refund within 30 days after ordering, whichever you prefer."])
      allPlans.plans["Lifetime"][index].url.directLink = allPlans.plans["Lifetime"][index].url.directLink.replace('awesome', 'gunbotcom')
    }
  });
}
*/


export default allPlans