import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { Suspense, lazy } from 'react';
import { css } from "styled-components/macro"; //eslint-disable-line

import ScrollToTop from "components/custom/ScrollToTop.js";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import Content from "_content/Content.js"

const Home = lazy(() => import("_pages/Home.js"));
const Features = lazy(() => import("_pages/Features.js"));
const How = lazy(() => import("_pages/How.js"));
const UseCases = lazy(() => import("_pages/UseCases.js"));
const Demo = lazy(() => import("_pages/Demo.js"));
const GetStarted = lazy(() => import("_pages/GetStarted.js"));
const Contact = lazy(() => import("_pages/Contact.js"));
const BinanceCalculator = lazy(() => import("_pages/BinanceCalculator.js"));
const SeoLandingPageTemplate = lazy(() => import("_pages/SeoLandingPageTemplate.js"));
const Exchanges = lazy(() => import("_pages/Exchanges.js"));
const CustomStrategies = lazy(() => import("_pages/CustomStrats.js"));
const Standard = lazy(() => import("_pages/plans/Standard.js"));
const Pro = lazy(() => import("_pages/plans/Pro.js"));
const Ultimate = lazy(() => import("_pages/plans/Ultimate.js"));
const Upgrade = lazy(() => import("_pages/plans/upgrade/Upgrade.js"));
const OneToStandard = lazy(() => import("_pages/plans/upgrade/OneToStandard"));
const OneToPro = lazy(() => import("_pages/plans/upgrade/OneToPro"));
const OneToUltimate = lazy(() => import("_pages/plans/upgrade/OneToUltimate"));
const StarterToStandard = lazy(() => import("_pages/plans/upgrade/StarterToStandard"));
const StarterToPro = lazy(() => import("_pages/plans/upgrade/StarterToPro"));
const StarterToUltimate = lazy(() => import("_pages/plans/upgrade/StarterToUltimate"));
const StandardToPro = lazy(() => import("_pages/plans/upgrade/StandardToPro"));
const StandardToUltimate = lazy(() => import("_pages/plans/upgrade/StandardToUltimate"));
const ProToUltimate = lazy(() => import("_pages/plans/upgrade/ProToUltimate"));
const PromoUltimateToUltimate = lazy(() => import("_pages/plans/upgrade/PromoUltimateToUltimate"));
const PairPerformance = lazy(() => import("_pages/performance/PairPerformance"));
const Performance = lazy(() => import("_pages/performance/Performance.js"));
const Download = lazy(() => import("_pages/Download.js"));
const Resellers = lazy(() => import("_pages/Resellers.js"));
const Defi = lazy(() => import("_pages/defi/Defi.js"));
const DevStrats = lazy(() => import("_pages/showcase/DevStrats.js"));
const DevCommunity = lazy(() => import("_pages/DevCommunity.js"));
const Completed = lazy(() => import("_pages/ConfirmAndRedirect.js"));
const Cancelled = lazy(() => import("_pages/CancelledAndRedirect.js"));
const Backtesting = lazy(() => import("_pages/Backtesting.js"));

export default function App() {
  let botPairs = []
  const ports = Object.keys(Content.bots) || []
  ports.forEach(port => {
    Content.bots[port].forEach(pair => {
      const base = pair.split('-')[0]
      const quote = pair.split('-')[1]
      botPairs.push({
        port: port,
        base: base,
        quote: quote,
        pair: pair,
        exchange: 'binance'
      })
    });
  })

  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<div style={{ backgroundColor: '#222', minWidth: '100%', minHeight: '100%' }}></div>}>
          <Switch>
            <Route path="/" exact render={(props) => <Home {...props} language="en" translationsFolder="home" />} />
            <Route path="/features/" exact render={(props) => <Features {...props} language="en" translationsFolder="features" />} />
            <Route path="/how-gunbot-works/" exact render={(props) => <How {...props} language="en" translationsFolder="how-gunbot-works" />} />
            <Route path="/use-cases/" exact render={(props) => <UseCases {...props} language="en" translationsFolder="use-cases" />} />
            <Route path="/live-demo/" exact render={(props) => <Demo {...props} language="en" translationsFolder="live-demo" />} />
            <Route path="/defi-trading-bot/" exact render={(props) => <Defi {...props} language="en" translationsFolder="defi-trading-bot" />} />
            <Route path="/plans/" exact render={(props) => <GetStarted {...props} language="en" translationsFolder="plans" />} />
            <Route path="/plans/standard/" exact render={(props) => <Standard {...props} />} />
            <Route path="/plans/pro/" exact render={(props) => <Pro {...props} />} />
            <Route path="/plans/ultimate/" exact render={(props) => <Ultimate {...props} />} />
            <Route path="/plans/upgrade/" exact render={(props) => <Upgrade {...props} language="en" translationsFolder="plans_upgrade" />} />
            <Route path="/plans/upgrade/one-to-standard/" exact render={(props) => <OneToStandard {...props} />} />
            <Route path="/plans/upgrade/one-to-pro/" exact render={(props) => <OneToPro {...props} />} />
            <Route path="/plans/upgrade/one-to-ultimate/" exact render={(props) => <OneToUltimate {...props} />} />
            <Route path="/plans/upgrade/starter-to-standard/" exact render={(props) => <StarterToStandard {...props} />} />
            <Route path="/plans/upgrade/starter-to-pro/" exact render={(props) => <StarterToPro {...props} />} />
            <Route path="/plans/upgrade/starter-to-ultimate/" exact render={(props) => <StarterToUltimate {...props} />} />
            <Route path="/plans/upgrade/standard-to-pro/" exact render={(props) => <StandardToPro {...props} />} />
            <Route path="/plans/upgrade/standard-to-ultimate/" exact render={(props) => <StandardToUltimate {...props} />} />
            <Route path="/plans/upgrade/pro-to-ultimate/" exact render={(props) => <ProToUltimate {...props} />} />
            <Route path="/plans/upgrade/ultimate-to-unlimited-slots/" exact render={(props) => <PromoUltimateToUltimate {...props} />} />
            <Route path="/features/custom-strategies/" exact render={(props) => <CustomStrategies {...props} language="en" translationsFolder="features_custom-strategies" />} />
            <Route path="/contact/" exact render={(props) => <Contact {...props} language="en" translationsFolder="contact" />} />
            <Route path="/binance-profit-calculator/" exact render={(props) => <BinanceCalculator {...props} language="en" translationsFolder="binance-profit-calculator" />} />
            <Route path="/trading/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/trading/day-trading/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/trading/trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/trading/trading-strategies/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/trading/trading-system/" exact render={(props) => <SeoLandingPageTemplate {...props} language="en" translationsFolder="trading_trading-system" />} />
            <Route path="/cryptocurrency/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/cryptocurrency/trading-for-beginners/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/" exact render={(props) => <Exchanges {...props} language="en" translationsFolder="exchanges" />} />
            <Route path="/exchanges/okx-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} l />} />
            <Route path="/exchanges/hitbtc-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/binance-us-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/bitstamp-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/binance-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/bitget-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/bingx-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/bitfinex-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/bybit-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/cryptocom-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/cex-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/coinbase-advanced-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/huobi-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/kraken-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/kucoin-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/poloniex-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/dydx-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/mexc-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/exchanges/gateio-trading-bot/" exact render={(props) => <SeoLandingPageTemplate {...props} />} />
            <Route path="/performance/" exact render={(props) => <Performance {...props} language="en" translationsFolder="performance" />} />
            <Route path="/downloads/" exact render={(props) => <Download {...props} language="en" translationsFolder="downloads" />} />
            <Route path="/resellers/" exact render={(props) => <Resellers {...props} language="en" translationsFolder="resellers" />} />
            <Route path="/devcommunity/quantaexotrader/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/quantaexotrader" />} />
            <Route path="/devcommunity/broxynet/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/broxynet" />} />
            <Route path="/devcommunity/channelmaestro/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/channelmaestro" />} /> 
            <Route path="/devcommunity/machinegun/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/machinegun" />} /> 
            <Route path="/devcommunity/gtrendspot/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/gtrendspot" />} /> 
            <Route path="/devcommunity/gtrendfutures/" exact render={(props) => <DevStrats {...props} language="en" translationsFolder="devstrats/gtrendfutures" />} />
            <Route path="/devcommunity/" exact render={(props) => <DevCommunity {...props} language="en" translationsFolder="devcommunity" />} />  
            <Route path="/confirmation/" exact render={(props) => <Completed {...props} />} />
            <Route path="/cancelled/" exact render={(props) => <Cancelled {...props} />} />
            <Route path="/backtesting/" exact render={(props) => <Backtesting {...props} />} />
            {botPairs.map(item => (
              <Route key={`${item.quote}${item.base}`} path={`/performance/${item.quote}${item.base}/`} exact render={(props) => <PairPerformance {...props} pair={item.pair} base={item.base} quote={item.quote} exchange={item.exchange} port={item.port} language="en" translationsFolder={`performance_pair-pairformance`} />} />
            ))}
            {/* spanish translated routes */}
            <Route path="/es/" exact render={(props) => <Home {...props} language="es" translationsFolder="home" />} />
            <Route path="/es/caracteristicas/" exact render={(props) => <Features {...props} language="es" translationsFolder="features" />} />
            <Route path="/es/como-funciona-gunbot/" exact render={(props) => <How {...props} language="es" translationsFolder="how-gunbot-works" />} />
            <Route path="/es/formas-de-uso/" exact render={(props) => <UseCases {...props} language="es" translationsFolder="use-cases" />} />
            <Route path="/es/demo-en-vivo/" exact render={(props) => <Demo {...props} language="es" translationsFolder="live-demo" />} />
            <Route path="/es/bot-de-trading-defi/" exact render={(props) => <Defi {...props} language="es" translationsFolder="defi-trading-bot" />} />
            <Route path="/es/planes/" exact render={(props) => <GetStarted {...props} language="es" translationsFolder="plans" />} />
            <Route path="/es/planes/actualizaciones/" exact render={(props) => <Upgrade {...props} language="es" translationsFolder="plans_upgrade" />} />
            <Route path="/es/caracteristicas/estrategias-personalizadas/" exact render={(props) => <CustomStrategies {...props} language="es" translationsFolder="features_custom-strategies" />} />
            <Route path="/es/contactenos/" exact render={(props) => <Contact {...props} language="es" translationsFolder="contact" />} />
            <Route path="/es/exchanges/" exact render={(props) => <Exchanges {...props} language="es" translationsFolder="exchanges" />} />
            <Route path="/es/rendimiento/" exact render={(props) => <Performance {...props} language="es" translationsFolder="performance" />} />
            <Route path="/es/descargas/" exact render={(props) => <Download {...props} language="es" translationsFolder="downloads" />} />
            <Route path="/es/vendedores/" exact render={(props) => <Resellers {...props} language="es" translationsFolder="resellers" />} />
            {botPairs.map(item => (
              <Route key={`${item.quote}${item.base}`} path={`/es/rendimiento/${item.quote}${item.base}/`} exact render={(props) => <PairPerformance {...props} pair={item.pair} base={item.base} quote={item.quote} exchange={item.exchange} port={item.port} language="es" translationsFolder={`performance_pair-pairformance`} />} />
            ))}
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

